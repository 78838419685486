import { TagPageTemplateData } from '@hubcms/domain-section';
import { ArticleTeaserData } from '@hubcms/domain-teaser';
import { Elements } from '@hubcms/feature-story-elements';
import { StoryHeader, StoryTitle, StoryIntro, StorySection } from '@hubcms/ui-story';
import { TagPageBlock } from '@hubcms/ui-teaser-layout';

import styles from './tagpage.module.scss';

type TagPageTemplateProps = {
  templateData: TagPageTemplateData;
  tagBlockArticles: ArticleTeaserData[];
};

function TagPageTemplate({ templateData: { storyHeaderData, bodyElements }, tagBlockArticles }: TagPageTemplateProps) {
  return (
    <>
      <div className={styles.introGrid}>
        <StoryHeader className={styles.header}>
          <StoryTitle {...storyHeaderData} headingProps={{ size: '2xl' }} />
          {storyHeaderData.introData && <StoryIntro {...storyHeaderData.introData} />}
        </StoryHeader>
        {bodyElements && (
          <StorySection className={styles.storylineContent}>
            <Elements elements={bodyElements} />
          </StorySection>
        )}
      </div>
      {tagBlockArticles.length ? <TagPageBlock articles={tagBlockArticles} theme="none" /> : null}
    </>
  );
}

export default TagPageTemplate;
